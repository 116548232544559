import i18n from "i18next";
import k from "src/i18n/keys";

export const comparisonCharts = {
  DualYAxis: "column-area-line",
  StackedArea: "stacked-area-line",
  MultiSeries: "ms-stacked-column-line"
};

export const operations = {
  count: i18n.t(k.RECORD_COUNT),
  sum: i18n.t(k.SUM),
  avg: i18n.t(k.AVERAGE),
  "avg-sum": i18n.t(k.AVERAGE_OF_SUM)
};

export const comparisonValues = {
  line: "line",
  area: "area",
  column: "column",
  rate: "rate"
};

export const trendlineColors = {
  "#3A86FF": {
    id: 1,
    name: "Blue",
    hexCode: "#3A86FF"
  },
  "#390099": {
    id: 2,
    name: "Violet",
    hexCode: "#390099"
  },
  "#5F00FF": {
    id: 3,
    name: "Purple",
    hexCode: "#5F00FF"
  },
  "#00F0FF": {
    id: 4,
    name: "Sky",
    hexCode: "#00F0FF"
  },
  "#9346FF": {
    id: 5,
    name: "Lavender",
    hexCode: "#9346FF"
  },
  "#BD00FF": {
    id: 6,
    name: "Orchid",
    hexCode: "#BD00FF"
  },
  "#9E0059": {
    id: 7,
    name: "Dark Pink",
    hexCode: "#9E0059"
  },
  "#FF00B8": {
    id: 8,
    name: "Fuschia",
    hexCode: "#FF00B8"
  },
  "#FF006E": {
    id: 9,
    name: "Pink",
    hexCode: "#FF006E"
  },
  "#FB5607": {
    id: 10,
    name: "Orange",
    hexCode: "#FB5607"
  },
  "#FF001F": {
    id: 11,
    name: "Red",
    hexCode: "#FF001F"
  },
  "#F0F408": {
    id: 12,
    name: "Lime",
    hexCode: "#F0F408"
  },
  "#FFBE0B": {
    id: 13,
    name: "Yellow",
    hexCode: "#FFBE0B"
  },
  "#D9C300": {
    id: 14,
    name: "Mustard",
    hexCode: "#D9C300"
  },
  "#CFEC20": {
    id: 15,
    name: "Green",
    hexCode: "#CFEC20"
  }
};

export const seriesConfig = new Map([
  [
    "conversation-count-stacked",
    {
      title: null,
      seqNo: 1,
      computationType: "conversation",
      computationField: null,
      operation: "count",
      rateSeries: null,
      rateOperation: null,
      axisType: "primary",
      plotType: "column",
      hide: false
    }
  ],
  [
    "conversation-count-doughnut",
    {
      y: null,
      title: null,
      seqNo: 1,
      computationType: "conversation",
      computationField: null,
      operation: "count",
      rateSeries: null,
      rateOperation: null,
      axisType: "primary",
      plotType: "column",
      hide: false
    }
  ],
  [
    "form-count-stacked",
    {
      title: null,
      seqNo: 1,
      computationType: "form",
      operation: "count",
      rateSeries: null,
      rateOperation: null,
      axisType: "primary",
      plotType: "column",
      hide: false
    }
  ],
  [
    "form-count-doughnut",
    {
      y: null,
      title: null,
      seqNo: 1,
      computationType: "form",
      operation: "count",
      rateSeries: null,
      rateOperation: null,
      axisType: "primary",
      plotType: "column",
      hide: false
    }
  ],
  [
    "numeric-field-stacked",
    {
      y: null,
      title: null,
      seqNo: 1,
      computationType: "number-field",
      operation: "sum",
      rateSeries: null,
      rateOperation: null,
      axisType: "primary",
      plotType: "column",
      hide: false
    }
  ],
  [
    "time-series-stacked",
    {
      y: null,
      title: null,
      seqNo: 1,
      computationType: "number-field",
      operation: "sum",
      rateSeries: null,
      rateOperation: null,
      axisType: "primary",
      plotType: "column",
      hide: false
    }
  ]
]);
