// @flow

import { connect } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import onClickOutside from "react-onclickoutside";
import * as R from "ramda";

import { List as StyledList, Input, ListItem } from "../styles";
import { searchGroup } from "src/actions/group";
import Name from "src/components/Group/Name";
import { setUserAnalytics } from "src/actions/userAnalytics";
import Checkbox from "src/components/Checkbox";

import type { AppState, UserAnalyticsFilter } from "src/types";

type Props = {
  searchResult: Array<string>,
  outerRef: any,
  filter: UserAnalyticsFilter,
  _searchGroup: Function,
  _setUserAnalytics: Function,
  handleClose: Function
};

const List = ({
  outerRef,
  searchResult,
  filter,
  _searchGroup,
  _setUserAnalytics,
  handleClose
}: Props) => {
  const [text, setText] = useState("");

  List.handleClickOutside = useCallback(
    (e: any) => {
      if (outerRef) {
        if (outerRef.current && !outerRef.current.contains(e.target)) {
          handleClose();
        }
      }
    },
    [outerRef, handleClose]
  );

  useEffect(() => {
    _searchGroup("");
  }, [_searchGroup]);

  const handleSearch = useCallback(
    (e: any) => {
      const { value } = e.target;
      setText(value);
      _searchGroup(value);
    },
    [text, setText, _searchGroup]
  );

  const handleGroupSelect = useCallback(
    (e: any, id: string) => {
      e.preventDefault();
      const group = (filter || {}).group || [];

      const newGroup = R.includes(id, group)
        ? R.difference(group, [id])
        : [...group, id];

      _setUserAnalytics({
        query: {
          ...filter,
          group: newGroup
        }
      });
    },
    [filter, _setUserAnalytics]
  );

  const group = (filter || {}).group || [];

  return (
    <StyledList>
      <Input
        type="text"
        value={text}
        placeholder="Search groups"
        onChange={handleSearch}
        autoFocus
      />

      <ul>
        {searchResult.map(g => (
          <ListItem onClick={e => handleGroupSelect(e, `${g}`)} key={g}>
            <>
              <Checkbox
                id={`group${g}`}
                handleChange={() => {}}
                checked={R.includes(`${g}`, group)}
              />

              <Name id={g} />
            </>
          </ListItem>
        ))}
      </ul>
    </StyledList>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: app.userAnalytics.filter,
  searchResult: app.groups.searchResult
});

const clickOutsideConfig = {
  handleClickOutside: () => List.handleClickOutside
};

export default connect(mapStateToProps, {
  _searchGroup: searchGroup,
  _setUserAnalytics: setUserAnalytics
})(onClickOutside(List, clickOutsideConfig));
