// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useCallback } from "react";

import { FormGroup, FilterGroup, TileColor, Content } from "./styles";
import { Input } from "src/styles/input";
import {
  setHomeScreenBuilderAttributes,
  createTile,
  editTile
} from "src/actions/orgSettings";
import tileColors from "src/styles/constants/tileColors";
import WorkflowSelect from "./WorkflowSelect";

import type { AppState } from "src/types";

type Props = {
  modal: Object,
  _setHomeScreenBuilderAttributes: Function
};

const StartNew = ({ modal, _setHomeScreenBuilderAttributes }: Props) => {
  const { workflow1, workflow2, workflow3, workflow4 } = modal;

  const handleTitle = useCallback(
    (event: any) => {
      _setHomeScreenBuilderAttributes({ title: event.target.value });
    },
    [_setHomeScreenBuilderAttributes]
  );

  const handleSubTitle = useCallback(
    (event: any) => {
      _setHomeScreenBuilderAttributes({ subTitle: event.target.value });
    },
    [_setHomeScreenBuilderAttributes]
  );

  const handleProcess1 = useCallback(
    (workflow: Object) => {
      _setHomeScreenBuilderAttributes({
        workflow1: workflow ? workflow.id : null
      });
    },
    [_setHomeScreenBuilderAttributes]
  );

  const handleProcess2 = useCallback(
    (workflow: Object) => {
      _setHomeScreenBuilderAttributes({
        workflow2: workflow ? workflow.id : null
      });
    },
    [_setHomeScreenBuilderAttributes]
  );

  const handleProcess3 = useCallback(
    (workflow: Object) => {
      _setHomeScreenBuilderAttributes({
        workflow3: workflow ? workflow.id : null
      });
    },
    [_setHomeScreenBuilderAttributes]
  );

  const handleProcess4 = useCallback(
    (workflow: Object) => {
      _setHomeScreenBuilderAttributes({
        workflow4: workflow ? workflow.id : null
      });
    },
    [_setHomeScreenBuilderAttributes]
  );

  return (
    <Content>
      <FormGroup>
        <label>{i18n.t(k.TITLE)}</label>
        <Input
          type="text"
          placeholder={i18n.t(k.PENDING_CONVERSATIONS)}
          value={modal.title}
          onChange={handleTitle}
          autoFocus
          required
        />
      </FormGroup>

      <FormGroup>
        <label>
          {i18n.t(k.SUB_TITLE)}
          <span>{i18n.t(k.OPTIONAL)}</span>
        </label>
        <Input
          type="text"
          placeholder={i18n.t(k.YOU_HAVE_PENDING_TASKS)}
          value={modal.subTitle}
          onChange={handleSubTitle}
        />
      </FormGroup>

      <WorkflowSelect
        id={workflow1}
        label="Start New"
        exclude={[workflow2, workflow3, workflow4]}
        onChange={handleProcess1}
      />

      <WorkflowSelect
        id={workflow2}
        label="Start New (Optional)"
        exclude={[workflow1, workflow3, workflow4]}
        onChange={handleProcess2}
      />

      <WorkflowSelect
        id={workflow3}
        label="Start New (Optional)"
        exclude={[workflow1, workflow2, workflow4]}
        onChange={handleProcess3}
      />

      <WorkflowSelect
        id={workflow4}
        label="Start New (Optional)"
        exclude={[workflow1, workflow2, workflow3]}
        onChange={handleProcess4}
      />

      <FormGroup>
        <label>{i18n.t(k.TILE_COLOR)}</label>
        <FilterGroup>
          {tileColors.map(color => (
            <TileColor
              onClick={() =>
                _setHomeScreenBuilderAttributes({ tileColor: color })
              }
              key={color}
              color={color}
              active={modal.tileColor === color}
            />
          ))}
        </FilterGroup>
      </FormGroup>
    </Content>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  modal: app.orgSettings.homeScreenBuilderDialog
});

export default connect(mapStateToProps, {
  _setHomeScreenBuilderAttributes: setHomeScreenBuilderAttributes,
  _createTile: createTile,
  _editTile: editTile
})(StartNew);
