// @flow

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import SingleSelect from "src/components/Unifize/Select/SingleSelect"; // Adjust the import based on your file structure
import Icon from "src/icons";
import { getWorkflowTemplates, getAllForms } from "src/reducers";

import * as colors from "src/styles/constants/colors";

import {
  FilterContainer,
  ClearAllButton,
  filterToggleButtonStyles
} from "./styles";

import type { TranslationQueryParams } from "src/types";

const processFilterData = [
  {
    id: "form",
    label: "Forms"
  },
  {
    id: "process",
    label: "Processes"
  }
];

const formEntities = [
  {
    id: "checklist-field",
    label: "Checklist Fields"
  }
];

type Props = {
  queryParams: TranslationQueryParams,
  setQueryParams: Function
};

const Filters = ({ queryParams, setQueryParams }: Props) => {
  const [firstValue, setFirstValue] = useState("");
  const [secondValue, setSecondValue] = useState("");
  const [thirdValue, setThirdValue] = useState("");

  const processes = useSelector(({ app }) => getWorkflowTemplates(app));
  const formsById = useSelector(({ app }) => getAllForms(app));

  const [secondFilterData, setSecondFilterData] = useState([]);
  const [secondFilterPlaceholder, setSecondFilterPlaceholder] = useState("");

  useEffect(() => {
    switch (firstValue) {
      case "process":
        setSecondFilterData(processes);
        setSecondFilterPlaceholder("process");
        break;
      case "form":
        setSecondFilterData(Object.values(formsById));
        setSecondFilterPlaceholder("form");
        break;
      default:
        setSecondFilterData([]);
        setSecondFilterPlaceholder("");
    }
  }, [firstValue]);

  const handleFirstSelect = value => {
    setFirstValue(value);

    setQueryParams(prev => ({
      ...prev,
      area: value,
      areaId: undefined,
      entity: undefined
    }));

    setSecondValue("");
    setThirdValue("");
  };

  const handleSecondSelect = value => {
    setSecondValue(value);

    if (queryParams.area === "process") {
      setQueryParams(prev => ({
        ...prev,
        areaId: value,
        entity: undefined
      }));
    } else if (queryParams.area === "form") {
      setQueryParams(prev => ({
        ...prev,
        areaId: value,
        entity: undefined
      }));
    }

    setThirdValue("");
  };

  const handleThirdSelect = value => {
    setThirdValue(value);
    setQueryParams(prev => ({
      ...prev,
      entity: value
    }));
  };

  const handleClearAll = () => {
    setFirstValue("");
    setSecondValue("");
    setThirdValue("");

    setQueryParams(prev => ({
      ...prev,
      area: undefined,
      areaId: undefined,
      entity: undefined
    }));
  };

  return (
    <FilterContainer>
      <SingleSelect
        selected={firstValue}
        value={firstValue}
        onChange={handleFirstSelect}
        placeholder="-- Choose the area --"
        data={processFilterData}
        keys={["id", "label"]}
        toggleButtonStyles={filterToggleButtonStyles}
      />
      {firstValue && (
        <SingleSelect
          selected={secondValue}
          value={secondValue}
          onChange={handleSecondSelect}
          placeholder={`-- Choose a ${secondFilterPlaceholder} --`}
          data={secondFilterData}
          keys={["id", "title"]}
          toggleButtonStyles={filterToggleButtonStyles}
        />
      )}
      {firstValue && secondValue && (
        <SingleSelect
          selected={thirdValue}
          value={thirdValue}
          onChange={handleThirdSelect}
          placeholder="-- Choose a custom field --"
          data={formEntities}
          keys={["id", "label"]}
          toggleButtonStyles={filterToggleButtonStyles}
        />
      )}
      {firstValue && (
        <ClearAllButton onClick={handleClearAll}>
          <Icon type="close" size={10} color={colors.active} stroke={1.5} />
          <span>Clear All</span>
        </ClearAllButton>
      )}
    </FilterContainer>
  );
};

export default Filters;
