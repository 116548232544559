import styled from "styled-components";
import * as colors from "src/styles/constants/colors";
import * as spacing from "src/styles/constants/spacing";
import * as radius from "src/styles/constants/radius";

export const SidebarItemContainer = styled.div`
  padding: ${spacing.space_m};
  width: 172px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing.space_m};
  background-color: ${props =>
    props.active ? colors.navyBlue : colors.brightLight};
  border-radius: ${radius.medium};
  cursor: pointer;
  &:hover {
    background-color: ${props =>
      props.active ? colors.navyBlue : colors.interactionHover};
  }
`;

export const Label = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${props => (props.active ? colors.brightLight : colors.grey39)};
  margin: 0;
`;

export const Container = styled.div`
  display: flex;
  max-height: 456px;
  gap: ${spacing.space_xxxl};
  flex-grow: 1;
`;

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.space_ms};
`;

export const NotificationSettingsContainer = styled.div`
  overflow: auto;
  > div {
    overflow: hidden;
    max-height: none;
  }
`;

export const LanguageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.space_s};
  width: 100%;
  height: 100%;
  flex-grow: 1;
  > span:first-child {
    font-weight: 700;
    line-height: 20px;
    margin: 0;
    font-size: 15px;
  }
`;

export const CustomToggleButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing.space_m};
  background: ${colors.brightLight};
  border: 1px solid ${colors.grey41};
  border-radius: ${radius.medium};
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${props => (props.isOpen ? "rotate(180deg)" : "rotate(0)")};
`;

export const CustomMenu = styled.div`
  position: absolute;
  width: 100%;
  border-radius: 0px 0px ${radius.medium} ${radius.medium};
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  > ul {
    padding: ${spacing.space_s} 0;
    margin: 0;
  }
`;

export const CustomMenuItem = styled.li`
  list-style-type: none;
  color: ${props => (props.selected ? colors.navyBlue : colors.black0)};
  font-weight: ${props => (props.selected ? "700" : "400")};
  font-size: ${props => (props.selected ? "15px" : "14px")};
  padding: ${spacing.space_s} ${spacing.space_m};
  background-color: ${props =>
    props.highlighted ? colors.interactionHighlight : colors.brightLight};
  &:hover {
    background-color: ${colors.interactionHover};
  }
`;

export const HelperText = styled.span`
  font-size: 12px;
  color: ${colors.grey39};
  font-weight: 100;
  line-height: 16px;
`;
