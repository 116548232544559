// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback, useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import type { HomeScreenBuilderDialog, State } from "src/types";
import { createTile, editTile } from "src/actions/orgSettings";

import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  IconButton,
  ModalFooter
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

import ButtonLoader from "src/components/ButtonLoader";
import Conversations from "./Conversations";
import MyConversationChart from "./MyConversationChart";
import StartNew from "./StartNew";
import DashboardChart from "./DashboardChart";
import * as styles from "./chakraStyles";

const modals = {
  conversations: {
    title: i18n.t(k.TILE_TYPE_CONVERSATIONS),
    component: Conversations
  },
  my_conversation_chart: {
    title: i18n.t(k.TILE_TYPE_MY_CONVERSATION_CHA),
    component: MyConversationChart
  },
  start_new: {
    title: i18n.t(k.TILE_TYPE_START_NEW),
    component: StartNew
  },
  dashboard_chart: {
    title: i18n.t(k.TILE_TYPE_DASHBOARD_CHART),
    component: DashboardChart
  }
};

type Props = {
  modal: HomeScreenBuilderDialog,
  modalType: string,
  _editTile: Function,
  _createTile: Function,
  handleClose: Function
};

const Modal = ({
  modal,
  modalType,
  _editTile,
  _createTile,
  handleClose
}: Props) => {
  const id = modal.id || null;

  const handleEsc = useCallback(
    (event: any) => {
      if (event.key === "Escape") {
        event.preventDefault();
        event.stopPropagation();
        handleClose();
      }
    },
    [handleClose]
  );

  const handleSubmit = useCallback(
    (event: any) => {
      event.preventDefault();
      if (id) {
        _editTile();
      } else {
        _createTile(modal);
      }
    },
    [id, modal, _createTile, _editTile]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEsc, false);
    return () => {
      document.removeEventListener("keydown", handleEsc, false);
    };
  }, []);

  const modalTitle = modals[modalType].title;
  const ModalType = modals[modalType].component;

  return (
    <ChakraModal isOpen onClose={handleClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent
        sx={{
          minWidth: "600px",
          height: "600px"
        }}
      >
        <ModalHeader textStyle="modalTitle" sx={styles.modalHeader}>
          {modalTitle}
          <IconButton
            variant="unstyled"
            icon={<CloseIcon />}
            onClick={handleClose}
          />
        </ModalHeader>
        <ModalBody sx={styles.modalBody}>
          <ModalType />
        </ModalBody>

        <ModalFooter sx={styles.modalFooter}>
          <ButtonLoader
            isLoading={modal.loading}
            type="submit"
            disabled={R.trim(modal?.title || "").length === 0}
            handleClick={handleSubmit}
          >
            {id ? i18n.t(k.SAVE_CHANGES) : i18n.t(k.CREATE1)}
          </ButtonLoader>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};

const mapStateToProps = ({ app }: State) => ({
  modal: app.orgSettings.homeScreenBuilderDialog
});

const mapDispatchToProps = {
  _createTile: createTile,
  _editTile: editTile
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
