// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import { FilterItem as StyledFilterItem, CloseButton } from "../styles";
import { processColumnNames } from "src/constants/display";
import * as colors from "src/styles/constants/colors";
import ChatroomName from "src/containers/chatroom/Name";
import Icon from "src/icons";

type Props = {
  id: any,
  column: string,
  handleClose: Function,
  blanksFilterText: ?string
};

const Parent = ({ id, column, handleClose, blanksFilterText }: Props) => {
  return (
    <StyledFilterItem>
      <span>
        {processColumnNames[column]}
        {i18n.t(k._)}
      </span>
      <strong>
        {blanksFilterText ? blanksFilterText : <ChatroomName id={id} />}
      </strong>
      <CloseButton onClick={handleClose}>
        <Icon type="close" color={colors.active} />
      </CloseButton>
    </StyledFilterItem>
  );
};

export default Parent;
