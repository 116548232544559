// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React from "react";
import Link from "redux-first-router-link";

import { Title as StyledTitle, NoTitle } from "./styles";
import Name from "src/containers/chatroom/Name";
import DirectChat from "./DirectChat";
import * as atypes from "src/constants/actionTypes";
import location from "src/constants/location";
import {
  getTemplateId,
  getChatroomTitle,
  getChatroomAddress,
  getChatroomType,
  getWorkflowTitle,
  getChatroomStatus
} from "src/reducers";

import type { AppState, RoomId, ChatroomAddress } from "src/types";

type Props = {
  id: RoomId,
  title: string,
  canceled: boolean,
  processName?: boolean,
  seqNo: ?number,
  templateId: number,
  openConversationModal: Function,
  address: ChatroomAddress,
  type: string,
  onClick?: Function,
  disableLink?: boolean
};

const Title = ({
  id,
  title,
  templateId,
  seqNo,
  canceled,
  openConversationModal,
  address,
  processName = false,
  type,
  onClick,
  disableLink
}: Props) => {
  const handleConversation = () => {
    if (onClick) {
      onClick();
    } else {
      openConversationModal(id);
    }
    return false;
  };

  if (disableLink) {
    return (
      <>
        <StyledTitle canceled={canceled}>
          {type === "direct" ? (
            <DirectChat title={title} />
          ) : (
            <>
              {processName ? (
                <Name
                  id={id}
                  noTitle={title ? title.trim().length === 0 : true}
                  name={title}
                  type={type}
                  seqNo={seqNo}
                  processTitle={processName}
                  canceled={canceled}
                  location={location.manageView}
                />
              ) : (
                title
              )}
            </>
          )}
        </StyledTitle>
        {(title && id) || processName ? (
          ""
        ) : (
          <NoTitle>{i18n.t(k.UNTITLED)}</NoTitle>
        )}
      </>
    );
  }

  return (
    <>
      <StyledTitle canceled={canceled}>
        <Link
          onClick={handleConversation}
          to={{
            type: atypes.SET_CURRENT_CHATROOM_REQUEST,
            payload: {
              id: address
            },
            meta: {
              query: {
                templateId
              }
            }
          }}
          title={title}
        >
          {type === "direct" ? (
            <DirectChat title={title} />
          ) : (
            <>
              {processName ? (
                <Name
                  id={id}
                  noTitle={title ? title.trim().length === 0 : true}
                  name={title}
                  type={type}
                  seqNo={seqNo}
                  processTitle={processName}
                  canceled={canceled}
                  location={location.manageView}
                />
              ) : (
                title
              )}
            </>
          )}
        </Link>
      </StyledTitle>
      {(title && id) || processName ? (
        ""
      ) : (
        <NoTitle>{i18n.t(k.UNTITLED)}</NoTitle>
      )}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  templateId: getTemplateId(app, props.id),
  title: props.title || getChatroomTitle(app, props.id),
  canceled:
    typeof props.canceled === "boolean"
      ? props.canceled
      : getChatroomStatus(app, props.id) === -3,
  processName:
    props.processName || getWorkflowTitle(app, getTemplateId(app, props.id)),
  address: props.address || getChatroomAddress(app, props.id),
  type: props.type || getChatroomType(app, props.id)
});

export default connect(mapStateToProps)(Title);
