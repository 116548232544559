// @flow

import { call, put, takeEvery } from "redux-saga/effects";
import i18n from "i18next";

import { changeLanguage } from "src/actions/language";
import * as atypes from "src/constants/actionTypes";
import * as api from "src/api/language";

function* handleUserLanguage(): any {
  try {
    const response = yield call(api.getLanguage);
    const { result: language } = response;
    if (language) {
      i18n.changeLanguage(language);
      localStorage.setItem("currentLanguage", language);
      yield put(changeLanguage(language));
    }
  } catch (error) {
    console.error("Error getting user language", error);

    // Fallback to EN in case of error / 404.
    i18n.changeLanguage("en");
    localStorage.setItem("currentLanguage", "en");
    yield put(changeLanguage("en"));
  }
}

function* watchUserLanguage(): any {
  yield takeEvery(atypes.API_AUTH_SUCCESS, handleUserLanguage);
}

export default [watchUserLanguage()];
