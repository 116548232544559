// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback } from "react";
import { connect } from "react-redux";

import { RoundButton } from "./styles";
import Icons from "src/icons";
import type { AppState, CurrentFilter } from "src/types";
import { showView } from "src/actions";
import { updateFilter } from "src/actions/filter";
import { getCurrentFilter } from "src/reducers";
import { fetchChatroomUpdates } from "src/actions/chatroom";
import useComponentPermission from "src/hooks/useComponentPermission";
import { componentPermissions } from "src/constants/roleManagement";

type Props = {
  isMine: boolean,
  _updateFilter: Function,
  _showView: Function,
  _fetchChatroomUpdates: Function,
  currentFilter: CurrentFilter
};

const Everyone = ({
  isMine,
  _showView,
  _updateFilter,
  currentFilter,
  _fetchChatroomUpdates
}: Props) => {
  const isDefaultWorkflow =
    currentFilter?.type.length === 1 && currentFilter.type[0];
  const name = isDefaultWorkflow
    ? isDefaultWorkflow
    : currentFilter.workflow
      ? currentFilter.name
      : "All Conversations";

  const setMineFilter = useCallback(() => {
    if (isMine) {
      _updateFilter({
        ...currentFilter,
        mine: false,
        unread: false,
        name
      });
      _showView("Everything");
      _fetchChatroomUpdates();
    } else {
      _updateFilter({
        ...currentFilter,
        mine: true
      });
      _showView("My Conversations");
    }
  }, [_updateFilter, isMine, _showView, currentFilter]);

  const filterByEveryoneEnabled = useComponentPermission(
    componentPermissions.filterEveryone
  );

  return (
    <RoundButton
      type="button"
      onClick={setMineFilter}
      onKeyPress={setMineFilter}
      active={!isMine}
      disabled={!filterByEveryoneEnabled}
    >
      <Icons type="everyOne" />
      {i18n.t(k.EVERYONE)}
    </RoundButton>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  isMine: app.chatRooms.filters.current.mine,
  currentFilter: getCurrentFilter(app)
});

export default connect(mapStateToProps, {
  _updateFilter: updateFilter,
  _showView: showView,
  _fetchChatroomUpdates: fetchChatroomUpdates
})(Everyone);
