// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import { Flex, Text, Box } from "@chakra-ui/react";

import Icon from "src/icons";
import * as styles from "./styles";
import {
  checklistFieldNames,
  checklistFieldIcons
} from "src/constants/display";
import * as colors from "src/styles/constants/colors";

import type { ChartField } from "src/types";

type Props = {
  selectedField: ChartField
};

const SelectedField = ({ selectedField }: Props) => {
  const {
    name,
    type,
    multiValue,
    linkedProcessOrForm,
    linkedProcessOrFormType,
    linkedOrFormFieldName
  } = selectedField;
  return (
    <Flex sx={{ gap: "1.5rem" }}>
      <Text sx={styles.FieldName} isTruncated>
        {name}
      </Text>

      {type && (
        <Text sx={styles.SelectedFieldType} isTruncated>
          <Icon
            type={
              type === "primaryRecord"
                ? "primaryRecord"
                : checklistFieldIcons[type].icon
            }
            width="14px"
            height="14px"
            fill={colors.inkLightest}
          />

          <Box as="span">
            {type === "primaryRecord"
              ? i18n.t(k.PRIMARY_RECORD)
              : checklistFieldNames[type]}
          </Box>
        </Text>
      )}

      {multiValue && (
        <Text sx={styles.SelectedFieldDetail} isTruncated>
          {i18n.t(k.MULTIPLE_VALUES)}
        </Text>
      )}

      {linkedProcessOrForm && (
        <Text sx={styles.SelectedFieldType} isTruncated>
          <Icon
            type={linkedProcessOrFormType === "form" ? "layout" : "anchor"}
            width="14px"
            height="14px"
            fill={colors.inkLightest}
          />

          <Box as="span">{linkedProcessOrForm}</Box>
        </Text>
      )}

      {linkedOrFormFieldName && (
        <Text sx={styles.SelectedFieldType}>
          <Icon type="commonChecklist" stroke={colors.inkLightest} />

          <Box as="span" isTruncated>
            {linkedOrFormFieldName}
          </Box>
        </Text>
      )}
    </Flex>
  );
};

export default SelectedField;
