import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import Type from "./Type";
import { Header, SubHeader, Email as StyledEmail } from "./styles";

const Email = () => (
  <StyledEmail>
    <Header>{i18n.t(k.EMAIL_NOTIFICATIONS)}</Header>
    <SubHeader>{i18n.t(k.SELECT_YOUR_PREFERED_NOTIFICAT)}</SubHeader>
    <Type />
  </StyledEmail>
);

export default Email;
