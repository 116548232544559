// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  useMemo
} from "react";
import * as R from "ramda";
import type { DownshiftProps } from "downshift";
import { useSelector, useDispatch } from "react-redux";
import ReactProgressiveList from "react-progressive-list";
import {
  Box,
  FormControl,
  FormLabel,
  Button,
  Skeleton,
  VStack,
  Flex,
  Spinner,
  Input
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

import {
  getPrincipalChecklistFieldsList,
  getChartRecordCountFieldList,
  getChartNumericalFieldList,
  getChartEmbeddedNumericalFieldList,
  getValueFromQuery,
  getLocationPayload,
  getChartRecordCountFieldListLoading,
  getChartNumericalFieldListLoading,
  getChartEmbeddedNumericalFieldListLoading
} from "src/reducers";
import DropdownRow from "src/components/PrimaryFieldDropdown/DropdownRow.js";
import {
  getRecordCountFieldList,
  getNumericalFieldList,
  getEmbeddedNumericalFieldList
} from "src/actions/chart";
import { operations } from "src/constants/charts";
import useDebounce from "src/hooks/useDebounce";
import Dropdown from "src/components/Dropdown";
import useBoolean from "src/hooks/useBoolean";
import {
  setChartSeries,
  handleComputationType,
  generateFieldId
} from "src/utils/charts.js";
import * as styles from "../styles";
import SelectedField from "src/components/Manage/Reports/Instances/Chart/New/ComparisonChart/Builder/SelectedField";
import { DropdownInputField } from "src/components/Dropdown/styles";

type Props = {
  seriesId: string,
  chartDetails: Object,
  operation: string,
  setChartDetails: Function,
  computationFieldId: number
};

const ComparisonType = ({
  seriesId,
  chartDetails,
  operation,
  setChartDetails,
  computationFieldId
}: Props) => {
  const downshiftRef = useRef<DownshiftProps>(null);
  const dispatch = useDispatch();

  const editId = useSelector(({ location }) =>
    getLocationPayload("id")(location)
  );

  const dropdownList = useSelector(({ app }) => {
    switch (operation) {
      case "count":
        return getChartRecordCountFieldList(app);

      case "sum":
      case "avg":
        return getChartNumericalFieldList(app);

      case "avg-sum":
        return getChartEmbeddedNumericalFieldList(app);

      default:
        break;
    }
  });

  const loading = useSelector(({ app }) => {
    switch (operation) {
      case "count":
        return getChartRecordCountFieldListLoading(app);

      case "sum":
      case "avg":
        return getChartNumericalFieldListLoading(app);

      case "avg-sum":
        return getChartEmbeddedNumericalFieldListLoading(app);

      default:
        break;
    }
  });

  const allFields = useSelector(({ app }) =>
    getPrincipalChecklistFieldsList(app)
  );
  const workflowId = useSelector(({ location }) =>
    getValueFromQuery("workflowId")(location)
  );

  const selectedField = dropdownList[computationFieldId];

  const memoizedFieldList = useMemo(
    () => R.values(dropdownList),
    [dropdownList]
  );

  const [query, setQuery] = useState("");
  const [filteredFields, setFilteredFields] = useState(memoizedFieldList);

  const {
    value: isOpen,
    setFalse: closeDropdown,
    toggleBoolean: toggleDropdown
  } = useBoolean();

  const getFieldList = type => {
    switch (type) {
      case "count":
        return dispatch(getRecordCountFieldList(allFields, workflowId));

      case "sum":
      case "avg":
        return dispatch(getNumericalFieldList(allFields, workflowId));

      case "avg-sum":
        return dispatch(getEmbeddedNumericalFieldList(workflowId));

      default:
        break;
    }
  };

  useEffect(() => {
    if (selectedField?.multiValue) {
      setChartSeries({
        setChartDetails,
        seriesId,
        key: "seriesMultiValue",
        value: selectedField.multiValue
      });
    }

    if (!R.isEmpty(memoizedFieldList)) {
      setFilteredFields(memoizedFieldList);
    }

    if (editId && R.isEmpty(dropdownList) && computationFieldId) {
      dispatch(getFieldList(operation));
    }
  }, [computationFieldId, memoizedFieldList, selectedField?.multiValue]);

  const filteredResults = query => {
    const result = memoizedFieldList.filter(({ name }) =>
      name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredFields(result);
  };

  const debouncedSearch = useDebounce(filteredResults, 300);

  const handleSearch = useCallback(
    input => {
      setQuery(input);
      debouncedSearch(input);
    },
    [debouncedSearch]
  );

  const handleSelect = ({ item: field }) => {
    operation &&
      handleComputationType({
        setChartDetails,
        seriesId,
        operation,
        fieldType: field.type
      });

    const {
      id,
      type,
      multiValue,
      linkedProcessOrFormType,
      linkedOrFormFieldId,
      linkedProcessOrFormId
    } = field;

    const selectedFieldId = generateFieldId({
      id,
      type,
      linkedProcessOrFormType,
      linkedProcessOrFormId,
      linkedOrFormFieldId
    });

    setChartSeries({
      setChartDetails,
      seriesId,
      key: "computationField",
      value: selectedFieldId,
      multiValue
    });

    closeDropdown();
  };

  const onKeyboardSelect = selectedIndex => {
    handleSelect({ item: filteredFields[selectedIndex] });
  };

  return (
    <FormControl sx={styles.FormLabel} isRequired>
      <FormLabel sx={styles.SeriesLabel}>
        {operation === "avg-sum"
          ? operations[operation]
          : `${operations[operation]} ${i18n.t(k.OF)}`}
      </FormLabel>

      <Box>
        <Button
          onClick={() => {
            toggleDropdown();
            R.isEmpty(dropdownList) && getFieldList(operation);
          }}
          sx={isOpen ? styles.DropdownExpanded : styles.DropdownButton}
          rightIcon={
            isOpen ? (
              <ChevronUpIcon w={6} h={6} />
            ) : (
              <ChevronDownIcon w={6} h={6} />
            )
          }
        >
          {loading ? (
            <Spinner size="sm" />
          ) : selectedField ? (
            <SelectedField selectedField={selectedField} />
          ) : (
            i18n.t(k.SELECT_FIELD)
          )}
        </Button>

        {isOpen && (
          <Dropdown
            onItemClick={handleSelect}
            isOpen={isOpen}
            onOuterClick={closeDropdown}
            ref={downshiftRef}
            onKeyboardSelect={onKeyboardSelect}
          >
            {({
              onItemClick,
              getItemProps,
              highlightedIndex,
              scrollIntoView,
              keyboardHandler
            }) => {
              return (
                <Flex sx={styles.DropdownList}>
                  <Input
                    placeholder={i18n.t(k.SEARCH)}
                    sx={DropdownInputField}
                    value={query}
                    onChange={event => handleSearch(event.target.value)}
                    onKeyDown={e =>
                      keyboardHandler({
                        event: e,
                        ref: downshiftRef,
                        onSelect: onKeyboardSelect,
                        onClose: closeDropdown
                      })
                    }
                    autoFocus
                  />

                  {loading ? (
                    <VStack sx={{ m: "0 0.5rem 0.5rem" }}>
                      <Skeleton sx={styles.Skeleton} />
                      <Skeleton sx={styles.Skeleton} />
                      <Skeleton sx={styles.Skeleton} />
                      <Skeleton sx={styles.Skeleton} />
                    </VStack>
                  ) : (
                    <ReactProgressiveList
                      initialAmount={12}
                      progressiveAmount={5}
                      role="list"
                      rowCount={filteredFields.length}
                      renderItem={index => {
                        if (filteredFields[index]) {
                          return (
                            <DropdownRow
                              isComputation
                              selectedField={selectedField}
                              seriesId={seriesId}
                              chartDetails={chartDetails}
                              item={filteredFields[index]}
                              key={filteredFields[index].id}
                              elId={index}
                              index={index}
                              highlightedIndex={highlightedIndex}
                              scrollIntoView={scrollIntoView}
                              {...getItemProps({
                                item: filteredFields[index],
                                index,
                                id: filteredFields[index].id,
                                onItemClick: onItemClick
                              })}
                            />
                          );
                        }
                      }}
                    />
                  )}
                </Flex>
              );
            }}
          </Dropdown>
        )}
      </Box>
    </FormControl>
  );
};

export default ComparisonType;
