// @flow

import React from "react";
import {
  Container,
  Selection,
  Label,
  Placeholder,
  ToggleButton,
  Menu,
  MenuItem,
  ItemTitle,
  ArrowIcon
} from "./styles";
import Icons from "src/icons";
import { useSelect } from "downshift";

type Props = {
  width?: string,
  height?: string,
  label?: string,
  placeholder?: string,
  data: Array<Object>,
  selected: string,
  onChange: Function,
  keys: Array<string>,
  isDisabled?: boolean,
  toggleButtonColor?: string,
  leftIcon?: React$Element<*>,
  toggleButtonStyles?: Object
};

const SingleSelect = ({
  width = "100%",
  height = "auto",
  label = "",
  placeholder = "",
  data,
  selected,
  onChange,
  keys,
  isDisabled = false,
  toggleButtonColor,
  leftIcon = null,
  toggleButtonStyles = {}
}: Props) => {
  const identifier: string = keys[0];
  const display: string = keys[1];

  const handleSelect = selected => {
    if (selected) {
      onChange(selected.selectedItem[identifier]);
    }
  };

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps
  } = useSelect({
    items: data,
    selectedItem: selected,
    onSelectedItemChange: handleSelect,
    itemToString: item => (item ? item.title : "")
  });

  return (
    <Container width={width} height={height}>
      <Selection>
        {label && <Label {...getLabelProps()}>{label}</Label>}
        <ToggleButton
          disabled={isDisabled}
          style={{ ...toggleButtonStyles }}
          toggleButtonColor={toggleButtonColor}
          {...getToggleButtonProps()}
        >
          <div
            style={{
              display: "flex",
              gap: "0.375rem",
              maxWidth: "250px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            }}
          >
            {leftIcon && leftIcon}
            <Placeholder isSelected={selected}>
              {selected && data.length > 0
                ? data.find(item => item[identifier] === selected)?.[display] ||
                  ""
                : placeholder}
            </Placeholder>
          </div>
          <ArrowIcon>
            {isOpen ? (
              <Icons type="caretUp" color={toggleButtonColor} />
            ) : (
              <Icons type="caretDown" color={toggleButtonColor} />
            )}
          </ArrowIcon>
        </ToggleButton>
      </Selection>
      <Menu {...getMenuProps()}>
        {isOpen &&
          data.map((item, index) => (
            <MenuItem
              className={
                (highlightedIndex === index ? "highlighted " : "") +
                (selected === item[identifier] ? "selected" : "")
              }
              key={item.id}
              {...getItemProps({ item, index })}
            >
              <ItemTitle>{item[display]}</ItemTitle>
            </MenuItem>
          ))}
      </Menu>
    </Container>
  );
};

export default SingleSelect;
