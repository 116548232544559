// @flow

import React from "react";
import { useSelector } from "react-redux";
import * as R from "ramda";

import { SectionLabel } from "../styles";
import Accordion from "src/components/Accordion.v2";
import AccordionContent from "../AccordionContent";
import { Checkbox, MultiSelect } from "src/components/Unifize";
import {
  getAllActiveUsers,
  getAllGroups,
  getCurrentChecklistBuilderFields,
  getAllRolesById
} from "src/reducers";
import { forbiddenFieldTypes } from "src/components/Dock/Checklist/Revision/utils";
import type { ApprovalFieldSettingsV2 } from "src/types";

type Props = {
  settings: ApprovalFieldSettingsV2,
  saveSettings: Function
};

const Cancelling = ({ settings, saveSettings }: Props) => {
  const checklistFields = useSelector(({ app }) =>
    R.reject(
      field => forbiddenFieldTypes.includes(field.type),
      getCurrentChecklistBuilderFields(app)
    )
  );
  const userFields = checklistFields.filter(field => field.type === "user");
  const users = useSelector(({ app }) => getAllActiveUsers(app));
  const groups = useSelector(({ app }) => getAllGroups(app));
  const rolesById = useSelector(({ app }) => getAllRolesById(app)).roles;
  const roles = Object.values(rolesById);
  return (
    <Accordion title="Cancelling approvals">
      <AccordionContent>
        <SectionLabel>Who can cancel</SectionLabel>
        <Checkbox
          checked={settings?.cancellers?.allParticipants}
          label="All participants"
          onChange={value =>
            saveSettings(["cancellers", "allParticipants"], value)
          }
        />

        <Checkbox
          checked={settings?.cancellers?.approvers}
          label="Approvers/Signatories"
          onChange={value => saveSettings(["cancellers", "approvers"], value)}
        />

        <MultiSelect
          label="Specific users"
          placeholder="Search users"
          data={users}
          selected={settings?.cancellers?.users || []}
          showIcon
          keys={["id", "name", "email", "photoUrl"]}
          onChange={value => saveSettings(["cancellers", "users"], value)}
        />

        <MultiSelect
          label="Roles"
          placeholder="Search roles"
          data={roles}
          selected={settings?.cancellers?.roles || []}
          showIcon
          keys={["id", "title", "photoUrl"]}
          onChange={value => saveSettings(["cancellers", "roles"], value)}
        />

        <MultiSelect
          label="Groups"
          placeholder="Search groups"
          data={groups}
          selected={settings?.cancellers?.groups || []}
          showIcon
          keys={["id", "name", "photoUrl"]}
          onChange={value => saveSettings(["cancellers", "groups"], value)}
        />

        <MultiSelect
          label="Users from these field(s)"
          placeholder="Search field"
          data={userFields}
          selected={settings?.cancellers?.fields || []}
          keys={["id", "label"]}
          onChange={value => saveSettings(["cancellers", "fields"], value)}
        />
      </AccordionContent>

      <SectionLabel>When can they cancel</SectionLabel>
      <Checkbox
        checked={settings?.allowCancelOnApproval}
        label="Allow cancellation post approval"
        onChange={value => saveSettings(["allowCancelOnApproval"], value)}
      />

      <Checkbox
        checked={settings?.canCancelPartialApproval}
        label="Allow cancelling of approvals in-progress"
        onChange={value => saveSettings(["canCancelPartialApproval"], value)}
      />
    </Accordion>
  );
};

export default Cancelling;
