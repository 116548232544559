// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React from "react";
import { Portal } from "@chakra-ui/react";

import Navigation from "./Navigation";
import Email from "./Email";
import { Settings as StyledSetting } from "./styles";
import Close from "src/containers/Close";

import type { AppState } from "src/types";

type Props = { current: string };

const Settings = ({ current }: Props) => {
  return (
    <Portal>
      <StyledSetting>
        <h2>{i18n.t(k.ORG_SETTINGS)}</h2>
        <Close />
        <div>
          <Navigation current={current} />
          <Email />
        </div>
      </StyledSetting>
    </Portal>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  current: app.settings.currentSection
});

export default connect(mapStateToProps)(Settings);
