// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useRef } from "react";
import { v4 as uuid } from "uuid";
import { Box, FormControl, FormLabel, Button } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

import Dropdown from "src/components/Dropdown";
import useBoolean from "src/hooks/useBoolean";
import SummaryTypeDropdownRow from "./SummaryTypeDropdownRow";
import * as styles from "../styles";
import { operations } from "src/constants/charts";
import { setChartSeries, keyboardHandler } from "src/utils/charts.js";

import type { DownshiftProps } from "downshift";

const seriesDropdown = [
  {
    id: uuid(),
    operation: "count",
    label: operations.count,
    subLabel: ""
  },
  {
    id: uuid(),
    operation: "sum",
    label: operations.sum,
    subLabel: i18n.t(k.TOTAL_OF_NUMERICAL_FIELD)
  },
  {
    id: uuid(),
    operation: "avg",
    label: operations.avg,
    subLabel: i18n.t(k.AVERAGE_OF_NUMERICAL_FIELD)
  },
  {
    id: uuid(),
    operation: "avg-sum",
    label: operations["avg-sum"],
    subLabel: i18n.t(k.NESTED_FIELDS_DESCRIPTION)
  }
];

type Props = {
  seriesId: number | string,
  operation: string,
  setChartDetails: Function
};

const SummaryType = ({ seriesId, operation, setChartDetails }: Props) => {
  const {
    value: isOpen,
    setFalse: closeDropdown,
    toggleBoolean: toggleDropdown
  } = useBoolean();

  const downshiftRef = useRef<DownshiftProps>(null);

  const handleSelect = ({ item }: { item: ?Object }) => {
    setChartSeries({
      setChartDetails,
      seriesId,
      key: "operation",
      value: item?.operation
    });
    closeDropdown();
  };

  const onKeyboardSelect = selectedIndex => {
    handleSelect({ item: seriesDropdown[selectedIndex] });
  };

  return (
    <FormControl sx={styles.FormLabel} isRequired>
      <FormLabel sx={styles.SeriesLabel}>{i18n.t(k.SUMMARY_TYPE)}</FormLabel>

      <Box>
        <Button
          onClick={toggleDropdown}
          onKeyDown={e =>
            keyboardHandler({
              event: e,
              ref: downshiftRef,
              onSelect: onKeyboardSelect,
              onClose: closeDropdown
            })
          }
          sx={isOpen ? styles.DropdownExpanded : styles.DropdownButton}
          rightIcon={
            isOpen ? (
              <ChevronUpIcon w={6} h={6} />
            ) : (
              <ChevronDownIcon w={6} h={6} />
            )
          }
        >
          {operations[operation] ?? i18n.t(k.SELECT)}
        </Button>

        {isOpen && (
          <Dropdown
            onItemClick={handleSelect}
            isOpen={isOpen}
            onOuterClick={closeDropdown}
            ref={downshiftRef}
          >
            {({
              onItemClick,
              getItemProps,
              highlightedIndex,
              scrollIntoView
            }) => {
              return seriesDropdown.map((list, index) => (
                <SummaryTypeDropdownRow
                  item={seriesDropdown[index]}
                  key={list.id}
                  elId={index}
                  index={index}
                  highlightedIndex={highlightedIndex}
                  scrollIntoView={scrollIntoView}
                  {...getItemProps({
                    item: seriesDropdown[index],
                    index,
                    id: seriesDropdown[index].id,
                    onItemClick: onItemClick
                  })}
                />
              ));
            }}
          </Dropdown>
        )}
      </Box>
    </FormControl>
  );
};

export default SummaryType;
