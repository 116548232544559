// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useCallback, useEffect, useRef } from "react";

import { Dropdown as StyledDropdown } from "../styles";
import { ListItemMedium } from "src/styles/box";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import useAutoPosition from "src/hooks/useAutoPosition";

type Props = {
  id: number,
  outerRef: any,
  handleClose: Function,
  handleOpenModal: Function
};

const Dropdown2 = ({ id, outerRef, handleOpenModal, handleClose }: Props) => {
  const dropdownRef = useRef(null);
  const { top, bottom } = useAutoPosition({
    outerRef: dropdownRef,
    initialTop: "3em",
    initialBottom: "auto",
    topOffset: "3em",
    bottomOffset: "3em"
  });

  const closeModal = useCallback(
    (event: any) => {
      if (event.key === "Escape") {
        event.preventDefault();
        event.stopPropagation();
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", closeModal, false);
    return () => {
      document.removeEventListener("keydown", closeModal, false);
    };
  }, []);

  const handleClickOutside = useCallback(
    (event: any) => {
      if (
        outerRef &&
        outerRef.current &&
        !outerRef.current.contains(event.target)
      ) {
        handleClose();
      }
    },
    [outerRef, handleClose]
  );

  return (
    <OutsideClickHandler onClickOutside={handleClickOutside}>
      <StyledDropdown top={top} bottom={bottom} ref={dropdownRef}>
        <ul>
          <ListItemMedium onClick={() => handleOpenModal(id, "conversations")}>
            {i18n.t(k.CONVERSATIONS)}
          </ListItemMedium>
          <ListItemMedium
            onClick={() => handleOpenModal(id, "my_conversation_chart")}
          >
            {i18n.t(k.MY_CONVERSATION_CHART)}
          </ListItemMedium>
          <ListItemMedium onClick={() => handleOpenModal(id, "start_new")}>
            {i18n.t(k.START_NEW)}
          </ListItemMedium>
          <ListItemMedium
            onClick={() => handleOpenModal(id, "dashboard_chart")}
          >
            {i18n.t(k.PIN_A_DASHBOARD_CHART)}
          </ListItemMedium>
        </ul>
      </StyledDropdown>
    </OutsideClickHandler>
  );
};

export default Dropdown2;
