// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useCallback } from "react";
import * as R from "ramda";

import {
  FormGroup,
  FilterGroup,
  TileColor,
  InlineFormGroup,
  Content
} from "./styles";
import EmptyDropdown from "./EmptyDropdown";
import { Input } from "src/styles/input";
import { RoundButton } from "src/components/chatroom-nav/filter/styles";
import Icons from "src/icons";
import { setHomeScreenBuilderAttributes } from "src/actions/orgSettings";
import ProcessSelect from "src/containers/workflow/SelectMultiple";
import Radio from "src/components/Radio";
import tileColors from "src/styles/constants/tileColors";

import type { AppState } from "src/types";

type Props = {
  modal: Object,
  _setHomeScreenBuilderAttributes: Function
};

const MyConversationChart = ({
  modal,
  _setHomeScreenBuilderAttributes
}: Props) => {
  const filters = modal.filters || {};
  let workflow = [];

  if (filters.workflow && R.type(filters.workflow) === "Number") {
    workflow = [filters.workflow];
  } else {
    workflow = filters.workflow || [];
  }

  const exclude = filters.exclude || false;

  const handleTitle = useCallback(
    (event: any) => {
      _setHomeScreenBuilderAttributes({ title: event.target.value });
    },
    [_setHomeScreenBuilderAttributes]
  );

  const handleSubTitle = useCallback(
    (event: any) => {
      _setHomeScreenBuilderAttributes({ subTitle: event.target.value });
    },
    [_setHomeScreenBuilderAttributes]
  );

  const toggleUnread = useCallback(() => {
    if (filters.unread) {
      _setHomeScreenBuilderAttributes({
        filters: {
          ...filters,
          unread: false
        }
      });
    } else {
      _setHomeScreenBuilderAttributes({
        filters: { ...filters, unread: true }
      });
    }
  }, [filters, _setHomeScreenBuilderAttributes]);

  const toggleOwner = useCallback(() => {
    if (filters.owner) {
      _setHomeScreenBuilderAttributes({
        filters: {
          ...filters,
          owner: false
        }
      });
    } else {
      _setHomeScreenBuilderAttributes({
        filters: {
          ...filters,
          owner: true
        }
      });
    }
  }, [filters, _setHomeScreenBuilderAttributes]);

  const handleExclude = useCallback(() => {
    _setHomeScreenBuilderAttributes({
      filters: {
        ...filters,
        exclude: true
      }
    });
  }, [filters, _setHomeScreenBuilderAttributes]);

  const handleInclude = useCallback(() => {
    _setHomeScreenBuilderAttributes({
      filters: {
        ...filters,
        exclude: false
      }
    });
  }, [filters, _setHomeScreenBuilderAttributes]);

  const togglePending = useCallback(() => {
    if (filters.pending) {
      _setHomeScreenBuilderAttributes({
        filters: {
          ...filters,
          pending: false
        }
      });
    } else {
      _setHomeScreenBuilderAttributes({
        filters: {
          ...filters,
          pending: true
        }
      });
    }
  }, [filters, _setHomeScreenBuilderAttributes]);

  const toggleComplete = useCallback(() => {
    if (filters.complete) {
      _setHomeScreenBuilderAttributes({
        filters: {
          ...filters,
          complete: false
        }
      });
    } else {
      _setHomeScreenBuilderAttributes({
        filters: {
          ...filters,
          complete: true
        }
      });
    }
  }, [filters, _setHomeScreenBuilderAttributes]);

  const toggleOverdue = useCallback(() => {
    if (filters.overdue) {
      _setHomeScreenBuilderAttributes({
        filters: {
          ...filters,
          overdue: false
        }
      });
    } else {
      _setHomeScreenBuilderAttributes({
        filters: {
          ...filters,
          overdue: true
        }
      });
    }
  }, [filters, _setHomeScreenBuilderAttributes]);

  const handleProcess = useCallback(
    (selectedWorkflow: Object) => {
      const workflowId = selectedWorkflow.id;

      if (R.includes(workflowId, workflow)) {
        _setHomeScreenBuilderAttributes({
          filters: {
            ...filters,
            workflow: R.reject(R.equals(workflowId), workflow)
          }
        });
      } else {
        _setHomeScreenBuilderAttributes({
          filters: {
            ...filters,
            workflow: R.uniq([...workflow, workflowId])
          }
        });
      }
    },
    [filters, workflow, _setHomeScreenBuilderAttributes]
  );

  return (
    <Content>
      <FormGroup>
        <label>{i18n.t(k.TITLE)}</label>
        <Input
          type="text"
          placeholder={i18n.t(k.PENDING_CONVERSATIONS)}
          value={modal.title}
          onChange={handleTitle}
          autoFocus
          required
        />
      </FormGroup>

      <FormGroup>
        <label htmlFor="subtitle">
          {i18n.t(k.SUB_TITLE)}
          <span>{i18n.t(k.OPTIONAL)}</span>
        </label>
        <Input
          id="subtitle"
          type="text"
          placeholder={i18n.t(k.YOU_HAVE_PENDING_TASKS)}
          value={modal.subTitle}
          onChange={handleSubTitle}
        />
      </FormGroup>

      <FormGroup>
        <InlineFormGroup>
          <div>
            <Radio
              id="excludeProcess"
              checked={exclude}
              handleChange={handleExclude}
            />

            <label htmlFor="excludeProcess">{i18n.t(k.EXCLUDE_PROCESS)}</label>
          </div>
          <div>
            <Radio
              id="includeProcess"
              checked={!exclude}
              handleChange={handleInclude}
            />

            <label htmlFor="includeProcess">{i18n.t(k.INCLUDE_PROCESS)}</label>
          </div>
        </InlineFormGroup>
        <ProcessSelect value={workflow} handleChange={handleProcess} />
      </FormGroup>

      <FormGroup>
        <label>
          {i18n.t(k.COLUMNS)}
          <span>{i18n.t(k.SELECT_AT_LEAST_ONE)}</span>
        </label>
        <FilterGroup>
          <RoundButton
            type="button"
            active={filters.unread}
            onClick={toggleUnread}
          >
            <Icons type="unreads" />
            {i18n.t(k.UNREAD)}
          </RoundButton>

          <RoundButton
            type="button"
            active={filters.owner}
            onClick={toggleOwner}
          >
            <Icons type="iown" />
            {i18n.t(k.I_OWN)}
          </RoundButton>

          <RoundButton
            type="button"
            active={filters.pending}
            onClick={togglePending}
          >
            <Icons type="calendar" />
            {i18n.t(k.PENDING)}
          </RoundButton>

          <RoundButton
            type="button"
            active={filters.complete}
            onClick={toggleComplete}
          >
            <Icons type="calendar" />
            {i18n.t(k.COMPLETE)}
          </RoundButton>

          <RoundButton
            type="button"
            active={filters.overdue}
            onClick={toggleOverdue}
          >
            <Icons type="calendar" />
            {i18n.t(k.OVERDUE)}
          </RoundButton>
        </FilterGroup>
      </FormGroup>

      <FormGroup>
        <label>{i18n.t(k.WHEN_EMPTY)}</label>
        <EmptyDropdown
          emptyMessage={modal.emptyMessage}
          value={modal.displayEmpty}
          handleSelect={_setHomeScreenBuilderAttributes}
        />
      </FormGroup>

      <FormGroup>
        <label>{i18n.t(k.TILE_COLOR)}</label>
        <FilterGroup>
          {tileColors.map(color => (
            <TileColor
              onClick={() =>
                _setHomeScreenBuilderAttributes({ tileColor: color })
              }
              key={color}
              color={color}
              active={modal.tileColor === color}
            />
          ))}
        </FilterGroup>
      </FormGroup>
    </Content>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  modal: app.orgSettings.homeScreenBuilderDialog
});

export default connect(mapStateToProps, {
  _setHomeScreenBuilderAttributes: setHomeScreenBuilderAttributes
})(MyConversationChart);
