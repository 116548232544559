// @flow

import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo
} from "react";

import { Text, Modal, ModalHeader, ModalContent } from "@unifize/sarah";

import Header from "./Header";
import Body from "./Body";
import Filters from "./Filters";
import SingleSelect from "src/components/Unifize/Select/SingleSelect";
import Icon from "src/icons";
import { useLanguage } from "src/contexts/LanguageContext";

import { getTranslations, getOrgLanguages } from "src/api/translation";

import * as colors from "src/styles/constants/colors";
import SearchInput from "src/components/Unifize/Input/SearchInput";
import {
  HeaderRow,
  Container,
  HeaderContainer,
  SupportedLanguagesContainer,
  MenuBar,
  Footer,
  PageButton,
  PageIndicatorContainer,
  PageInput,
  ContentContainer,
  searchInput
} from "./styles";

import type { TranslationQueryParams } from "src/types";
import "react-virtualized/styles.css";

const Translations = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const containerRef = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [supportedLanguages, setSupportedLanguages] = useState({});
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [pageDebounceTimeout, setPageDebounceTimeout] = useState(null);

  const { language, setLanguage, setTranslations } = useLanguage();

  const primaryLanguageName = "English";

  const headers = [
    `${primaryLanguageName} (Primary Language)`,
    `${language !== null ? supportedLanguages[language].nativeName : ""} (Translated Language)`,
    "Path"
  ];

  const [queryParams, setQueryParams] = useState({
    lang: language,
    page: 1,
    pageSize: 23,
    search: "",
    area: undefined,
    areaId: undefined,
    entity: undefined
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getOrgLanguages().then(response => {
      const formattedLanguageData = {};
      if (response?.length) {
        response.map(lang => {
          formattedLanguageData[lang.langAbbr] = lang;
        });

        setSupportedLanguages(formattedLanguageData);

        if (Object.keys(formattedLanguageData).length > 1) {
          // Set the default language on the language dropdown
          for (let lang in formattedLanguageData) {
            if (!formattedLanguageData[lang].isPrimary) {
              setLanguage(lang);
              setQueryParams(prev => ({
                ...prev,
                lang
              }));
              break;
            }
          }
        }
      }
    });
  }, []);

  const fetchTranslations = useCallback(
    async (params?: TranslationQueryParams) => {
      if (isLoading || !queryParams.lang) return;

      try {
        const response = await getTranslations(params ?? queryParams);
        setTranslations(response.translations);
        setQueryParams(prev => ({
          ...prev,
          page: parseInt(response.pagination.currentPage),
          pageSize: parseInt(response.pagination.pageSize)
        }));
        setTotalPages(response.pagination.totalPages);
      } catch (err) {
        console.error(err);
      }
    },
    [JSON.stringify(queryParams), language]
  );

  useEffect(() => {
    setIsLoading(true);
    fetchTranslations().then(() => {
      setIsLoading(false);
    });
  }, [JSON.stringify(queryParams)]);

  const handleSearch = useCallback(
    query => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      const timeoutId = setTimeout(() => {
        setQueryParams(prev => ({
          ...prev,
          page: 1,
          search: query
        }));
      }, 300);

      setDebounceTimeout(timeoutId);
    },
    [debounceTimeout]
  );

  const changePage = useCallback((page: number) => {
    setQueryParams(prev => ({
      ...prev,
      page
    }));
  }, []);

  const nextPage = async () => {
    handlePageInputChange(queryParams.page + 1);
  };

  const previousPage = async () => {
    handlePageInputChange(queryParams.page - 1);
  };

  const handleLanguageSwitch = e => {
    setLanguage(e);
    setQueryParams(prev => ({
      ...prev,
      lang: e
    }));
  };

  const containerWidth = useMemo(() => {
    return containerRef.current?.clientWidth
      ? `${parseInt(containerRef.current?.clientWidth) - 48}px`
      : 0;
  }, [containerRef.current?.clientWidth]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handlePageInputChange = useCallback(
    value => {
      if (pageDebounceTimeout) {
        clearTimeout(pageDebounceTimeout);
      }

      const timeoutId = setTimeout(() => {
        const page = value ? Number(value) : 1;
        if (page >= 1 && page <= totalPages) {
          changePage(page);
        }
      }, 300);

      setPageDebounceTimeout(timeoutId);
    },
    [pageDebounceTimeout, changePage]
  );

  return (
    <Container ref={containerRef}>
      <Modal
        height="auto"
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <ModalHeader>Supported Languages</ModalHeader>
        <ModalContent>
          <ContentContainer>
            Languages supported by your org are:
            <ul>
              {Object.keys(supportedLanguages).map(lang => (
                <li key={lang}>{supportedLanguages[lang].nativeName}</li>
              ))}
            </ul>
            Contact Unifize to get support for more languages.
          </ContentContainer>
        </ModalContent>
      </Modal>
      <HeaderContainer>
        <Text variant="h2" weight="bold">
          Translations
        </Text>
        <SupportedLanguagesContainer onClick={openModal}>
          <Icon
            type="info"
            size={15}
            viewBoxSize={19}
            fillColor={colors.blue700}
          />
          <span>Support languages</span>
        </SupportedLanguagesContainer>
      </HeaderContainer>
      {supportedLanguages.length === 1 ? (
        <div
          style={{
            padding: "1rem"
          }}
        >
          <Text>
            Language support is not available for your org. Contact Unifize to
            get support for more languages
          </Text>
        </div>
      ) : (
        <>
          <MenuBar>
            <SearchInput
              placeholder="Search"
              onInput={handleSearch}
              style={searchInput}
            />
            <div>
              <SingleSelect
                selected={language ?? ""}
                placeholder=""
                data={Object.values(supportedLanguages).filter(
                  // $FlowFixMe
                  lang => !lang.isPrimary
                )}
                keys={["langAbbr", "nativeName"]}
                onChange={handleLanguageSwitch}
                toggleButtonColor={colors.blue700}
                leftIcon={
                  <Icon
                    type="translation"
                    style={{ width: "20px", height: "20px" }}
                    color={colors.blue700}
                  />
                }
              />
            </div>
          </MenuBar>
          {/* $FlowFixMe - doesn't use queryParams.lang */}
          <Filters queryParams={queryParams} setQueryParams={setQueryParams} />
          <div
            style={{
              height: "100%",
              overflow: "auto",
              margin: "1.5rem 1.5rem 0 1.5rem"
            }}
          >
            <HeaderRow style={{ width: containerWidth }}>
              {headers.map((header, index) => (
                <Header key={index} label={header} />
              ))}
            </HeaderRow>
            <div style={{ height: "24px", width: "100%" }}></div>
            <Body isLoading={isLoading} />
          </div>
          <Footer>
            <PageButton
              className="border rounded p-2"
              onClick={previousPage}
              disabled={queryParams.page <= 1}
            >
              {"<"}
            </PageButton>
            <PageButton
              className="border rounded p-2"
              onClick={nextPage}
              disabled={queryParams.page >= totalPages}
            >
              {">"}
            </PageButton>
            <PageIndicatorContainer>
              <div>Page</div>
              <strong>
                {queryParams.page} of {totalPages}
              </strong>
            </PageIndicatorContainer>
            <PageIndicatorContainer>
              | Go to page:
              <PageInput
                type="number"
                defaultValue={1}
                min={1}
                max={totalPages}
                onChange={e => handlePageInputChange(e.target.value)}
                className="border p-2 rounded w-16"
              />
            </PageIndicatorContainer>
          </Footer>
        </>
      )}
    </Container>
  );
};

export default Translations;
