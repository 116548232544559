// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import Common from "../Common";
import LinkedRoom from "../LinkedRoom";
import StatusValue from "./StatusValue";
import type { Message } from "src/types";

type Props = {
  message: Message
};

const Status = ({ message }: Props) => {
  const { data, roomId } = message;
  const { id, comment, new: newStatus } = data;
  const isCurrentRoom = id === parseInt(roomId, 10);
  if (isCurrentRoom)
    return (
      <Common {...message} comment={comment}>
        {" "}
        {i18n.t(k.UPDATED_STATUS_OF_THIS_CONVERS)}
        <StatusValue value={newStatus} roomId={id} />
      </Common>
    );

  return (
    <Common {...message} comment={comment}>
      {" "}
      {i18n.t(k.UPDATED_STATUS_OF_THE_CONVERSA)}{" "}
      <LinkedRoom id={`${data.roomId}`} />
      {i18n.t(k.TO)} <StatusValue value={newStatus} roomId={data.roomId} />
    </Common>
  );
};

export default Status;
