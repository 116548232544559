// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useCallback } from "react";
import * as R from "ramda";

import {
  Conversation as StyledConversation,
  SubTitle,
  ListItem as StyledListItem,
  Button as ButtonOutline,
  ChatroomName as StyledChatroomName
} from "./styles";
import { showView } from "src/actions";
import {
  setPreviousChatroom,
  fetchChatroomUpdates
} from "src/actions/chatroom";
import { ButtonLink } from "src/styles/buttons";
import { getFilteredConversations, getAllTemplateStatus } from "src/reducers";
import Link from "src/containers/chatroom/Name/Title";
import Name from "src/containers/chatroom/Name";
import { updateFilter } from "src/actions/filter";
import { initialFilterState } from "src/reducers/chatRooms";
import { openHomeScreenTile } from "src/actions/homescreen";

import type { AppState, HomescreenTile } from "src/types";

type Props = {
  rooms: Array<number>,
  tile: HomescreenTile,
  _showView: Function,
  _setPreviousChatroom: Function,
  _updateFilter: Function,
  _openHomeScreenTile: Function,
  isRoomCancelled: Function,
  _fetchChatroomUpdates: Function
};

const Conversations = ({
  rooms,
  tile,
  _showView,
  _setPreviousChatroom,
  _openHomeScreenTile,
  _updateFilter,
  _fetchChatroomUpdates
}: Props) => {
  const handleNavigate = useCallback(() => {
    _updateFilter({
      ...initialFilterState,
      ...(tile.settings.filters || {}),
      name: tile.title || "My Conversations",
      homescreen: true
    });
    _setPreviousChatroom();
    _showView("My Conversations");
    _openHomeScreenTile({ type: "clicked-tile", tileType: "My Conversations" });
    _fetchChatroomUpdates();
  }, [tile, _setPreviousChatroom, _showView, _updateFilter]);

  const handleClick = useCallback(() => {
    if (rooms.length > 5) {
      handleNavigate();
    }
  }, [rooms]);

  const remaining = rooms.length - 5;

  if (!tile.settings.displayEmpty && rooms.length === 0) {
    return <></>;
  }

  return (
    <StyledConversation
      clickable={rooms.length > 5}
      color={tile.settings.tileColor}
      onClick={handleClick}
    >
      {rooms.length > 0 && (
        <>
          <h4>{tile.title}</h4>
          {(tile.subTitle || "").length > 0 && (
            <SubTitle>
              {(tile.subTitle || "").replace(/#num/g, `${rooms.length || ""}`)}
            </SubTitle>
          )}
        </>
      )}

      {rooms.length === 0 ? (
        <>
          <h4>{tile.settings.emptyMessage?.title || ""}</h4>
          <p>{tile.settings.emptyMessage?.subTitle || ""}</p>
        </>
      ) : (
        <ul>
          {R.take(5, rooms).map(room => (
            <StyledListItem key={room}>
              {rooms.length > 5 ? (
                <StyledChatroomName>
                  <Name id={room} />
                </StyledChatroomName>
              ) : (
                <Link id={room} />
              )}
            </StyledListItem>
          ))}
        </ul>
      )}

      {remaining > 0 && (
        <ButtonLink type="button" onClick={handleNavigate}>
          {/* eslint-disable-next-line lingui/no-unlocalized-strings */}
          {i18n.t(k._13)} {remaining} more
        </ButtonLink>
      )}

      {rooms.length > 0 &&
        R.trim(tile.settings.buttonText || "").length > 0 && (
          <ButtonOutline type="button" onClick={handleNavigate}>
            {tile.settings.buttonText}
          </ButtonOutline>
        )}
    </StyledConversation>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => {
  const templateStatus = getAllTemplateStatus(app);
  return {
    rooms: getFilteredConversations(
      app,
      {
        ...initialFilterState,
        ...(props.tile.settings.filters || {})
      },
      templateStatus
    )
  };
};

export default connect(mapStateToProps, {
  _showView: showView,
  _setPreviousChatroom: setPreviousChatroom,
  _updateFilter: updateFilter,
  _openHomeScreenTile: openHomeScreenTile,
  _fetchChatroomUpdates: fetchChatroomUpdates
})(Conversations);
