import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Text,
  CheckboxGroup,
  Stack,
  Checkbox,
  RadioGroup,
  Radio
} from "@chakra-ui/react";

import { getChecklistLayoutSettings } from "src/reducers";
import { setWorkflowBuiderAttributes } from "src/actions/workflows";
import { defaultChecklistLayout } from "src/constants";

const Layout = () => {
  const dispatch = useDispatch();

  const checklistLayout = useSelector(({ app }) =>
    getChecklistLayoutSettings(app)
  );

  const layout = checklistLayout
    ? {
        ...checklistLayout,
        checklistOpenState:
          checklistLayout.checklistOpenState ?? checklistLayout.openStatus
      }
    : defaultChecklistLayout;

  const { checklistWidth } = layout;

  const [openStatus, setOpenStatus] = useState(() => {
    let status = [];
    for (const [key, value] of Object.entries(layout.checklistOpenState)) {
      if (value) status.push(key);
    }
    return status;
  });

  const handleChange = value => {
    if (typeof value === "object") {
      setOpenStatus(value);
      const updatedOpenStatus = { ...layout.checklistOpenState };
      for (let key in updatedOpenStatus) {
        updatedOpenStatus[key] = value.includes(key);
      }
      dispatch(
        setWorkflowBuiderAttributes({
          layout: { ...layout, checklistOpenState: updatedOpenStatus }
        })
      );
    } else {
      dispatch(
        setWorkflowBuiderAttributes({
          layout: { ...layout, checklistWidth: value }
        })
      );
    }
  };

  return (
    <Stack px={2} spacing={1}>
      <Text fontSize="lg">Checklist Layout Defaults</Text>

      <CheckboxGroup onChange={handleChange} value={openStatus}>
        <Text>Checklist open by default?</Text>
        <Stack spacing={[1, 2]} direction={["row", "column"]}>
          <Checkbox size="sm" value="web">
            Web App
          </Checkbox>
          <Checkbox size="sm" value="lite">
            Unifize Lite on Web
          </Checkbox>
          <Checkbox size="sm" value="liteMobile">
            Unifize Lite on Mobile (Navigate to checklist when opening
            conversation){" "}
          </Checkbox>
        </Stack>
      </CheckboxGroup>

      <RadioGroup onChange={handleChange} value={checklistWidth}>
        <Text>Default checklist width on Unifize web app</Text>
        <Stack spacing={2} direction="column">
          <Radio size="sm" value="1">
            Single Column
          </Radio>
          <Radio size="sm" value="2">
            Double Column
          </Radio>
          <Radio size="sm" value="3">
            Triple Column
          </Radio>
          <Radio size="sm" value="max">
            Split available screen space into 50% conversation and 50% checklist
          </Radio>
        </Stack>
      </RadioGroup>
    </Stack>
  );
};

export default Layout;
