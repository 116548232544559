// @flow

import React, { useEffect, useState } from "react";
import * as R from "ramda";
import { back } from "redux-first-router";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  setWorkflowChanges,
  setWorkflowChangesAndRedirect,
  hideWorkflowBuilder
} from "src/actions/workflows";
import { updateAppView } from "src/actions";
import type { State, UnifizeChatRoom, ChecklistBuilderField } from "src/types";
import {
  getLastVisitedRoomOfProcess,
  getCurrentChecklistBuilder,
  getChatRoom,
  getCurrentLanguage
} from "src/reducers";
import {
  Tabs,
  TabPanels,
  TabPanel,
  Tab,
  TabList,
  HStack,
  Button
} from "@chakra-ui/react";
import {
  ProcessOverlay,
  tabContainer,
  tabListContainer,
  ProcessBuilderFooter as Footer
} from "./styles";
import Title from "./Title";
import Template from "./Template";
import Status from "./Status";
import Privacy from "./Privacy";
import Advanced from "./Advanced";
import Reminders from "./Reminders";
import Checklist from "./Checklist";
import Deleted from "./Deleted";
import Layout from "./Layout";
import * as styles from "./chakraStyles";
import Tooltip from "src/components/Tooltip";
import { dataStages } from "src/constants";
import SpinnerLoader from "src/components/Loader/SpinnerLoader";

type Props = {
  setWorkflowChanges: Function,
  hideWorkflowBuilder: Function,
  defaultTabIndex: number,
  lastVisitedRoom: UnifizeChatRoom | null,
  setWorkflowChangesAndRedirect: Function,
  fields: ChecklistBuilderField[],
  language: string
};

const tabs = [
  {
    label: "Team",
    component: <Template />
  },
  {
    label: "Status",
    component: <Status />
  },
  {
    label: "Checklist",
    component: <Checklist />
  },
  {
    label: "Deleted Fields",
    component: <Deleted />
  },
  {
    label: "Privacy Settings",
    component: <Privacy />
  },
  {
    label: "Advanced Process Settings",
    component: <Advanced />
  },
  {
    label: "Reminders",
    component: <Reminders />
  },
  {
    label: "Layout",
    component: <Layout />
  }
];

const getRoomTitle = (room: UnifizeChatRoom) =>
  `#${String(room.autoNo || room.seqNo)}: ${room.title}`;

const ProcessBuilder = ({
  setWorkflowChanges,
  hideWorkflowBuilder,
  defaultTabIndex,
  lastVisitedRoom,
  setWorkflowChangesAndRedirect,
  fields,
  language
}: Props) => {
  const dispatch = useDispatch();
  const exportChecklistFields = () => {
    const data = JSON.stringify(fields);

    const blob = new Blob([data], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "checklist-fields.json";
    a.click();
  };

  const {
    title,
    header,
    loading,
    isModified,
    edit: isEditing
  } = useSelector(store => store.app.workflow.builderDialog);

  const isUpdating = loading === dataStages.updating;
  const isSaveDisabled = isUpdating || !isModified || !title.length;

  const [redirect, setRedirect] = useState(true);

  useEffect(() => {
    // Update the 'view' in store once this loads
    dispatch(updateAppView("manage"));
  }, []);

  useEffect(() => {
    // Reset workflow.builderDialog when process builder is unmounted
    return () => hideWorkflowBuilder();
  }, []);

  useEffect(() => {
    // Reset the default state to save and redirect, after each update
    if (loading === dataStages.updated) {
      setRedirect(true);
    }
  }, [loading]);

  const handleChange = (exit: boolean) => {
    setRedirect(exit);

    if (exit) {
      setWorkflowChangesAndRedirect();
    } else {
      setWorkflowChanges();
    }
  };

  return (
    <ProcessOverlay key={language}>
      {loading === dataStages.fetching ? (
        <SpinnerLoader />
      ) : (
        <>
          <header>
            <Title processTitle={title} />
          </header>

          <Tabs isLazy sx={styles.tabs} defaultIndex={defaultTabIndex}>
            <TabList sx={tabListContainer}>
              {tabs.map(tab => (
                <Tab
                  key={tab.label}
                  sx={tabContainer}
                  data-cy={`${tab.label}Tab`}
                >
                  {tab.label}
                </Tab>
              ))}
              <Tab sx={tabContainer} isDisabled>
                Notification Settings
              </Tab>
            </TabList>

            <TabPanels sx={styles.tabPanels}>
              {tabs.map(tab => (
                <TabPanel key={tab.label} sx={styles.tabPanel}>
                  {tab.component}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>

          <Footer>
            <HStack spacing={3}>
              <Button w="72px" variant="uSecondary" onClick={() => back()}>
                Exit
              </Button>
            </HStack>

            <HStack>
              <Button
                minW="128px"
                isDisabled={isSaveDisabled}
                isLoading={isUpdating && redirect}
                variant="uSecondary"
                onClick={() => handleChange(true)}
              >
                {isEditing ? "Save & Exit" : "Create & Exit"}
              </Button>
              <Tooltip
                title="Enter a title for this process"
                hide={title}
                placement="top"
                bg="red.600"
              >
                <Button
                  minW="72px"
                  data-cy="saveProcessTemplateButton"
                  isLoading={isUpdating && !redirect}
                  isDisabled={isSaveDisabled}
                  variant="uPrimary"
                  onClick={() => handleChange(false)}
                >
                  {isEditing ? "Save" : "Create Process"}
                </Button>
              </Tooltip>
            </HStack>
          </Footer>
        </>
      )}
    </ProcessOverlay>
  );
};

const mapStateToProps = ({ location, app }: State) => {
  const lastVisitedRoomId = !R.isNil(app.workflow.builderDialog.id)
    ? getLastVisitedRoomOfProcess(app, Number(app.workflow.builderDialog.id))
    : null;

  const lastVisitedRoom = lastVisitedRoomId
    ? getChatRoom(app, lastVisitedRoomId)
    : null;

  return {
    defaultTabIndex: Math.max(
      0,
      tabs.findIndex(tab => tab.label === location?.query?.tab)
    ),
    lastVisitedRoom,
    fields: getCurrentChecklistBuilder(app).fields,
    language: getCurrentLanguage(app)
  };
};

export default connect(mapStateToProps, {
  setWorkflowChanges,
  setWorkflowChangesAndRedirect,
  hideWorkflowBuilder
})(ProcessBuilder);
