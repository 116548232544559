// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useCallback, useEffect, useRef } from "react";
import { Set as ImmutableSet } from "immutable";

import {
  FormGroup,
  FilterGroup,
  TileColor,
  ProcessButton,
  RemoveButton,
  RemoveStatusButton,
  Content
} from "./styles";
import EmptyDropdown from "./EmptyDropdown";
import ProcessDropdown from "./ProcessDropdown";
import tileColors from "src/styles/constants/tileColors";
import { Input } from "src/styles/input";
import {
  RoundButton,
  ClearSort,
  Arrow
} from "src/components/chatroom-nav/filter/styles";
import Icons from "src/icons";
import { setHomeScreenBuilderAttributes } from "src/actions/orgSettings";
import { sorByItemNames } from "src/constants/defaultFilter";
import SortModal from "src/components/chatroom-nav/filter/SortModal";
import StatusModal from "./StatusModal";
import useBoolean from "src/hooks/useBoolean";
import WorkflowName from "src/containers/workflow/Name";
import { blue } from "src/styles/constants/colors";
import type { AppState } from "src/types";

type Props = {
  modal: Object,
  _setHomeScreenBuilderAttributes: Function
};

const Conversations = ({ modal, _setHomeScreenBuilderAttributes }: Props) => {
  const processRef = useRef();
  const {
    value: sortbyModal,
    toggleBoolean: toogleSortModal,
    setFalse: hideSortByModal
  } = useBoolean();
  const { value: statusModal, toggleBoolean: toggleStatusModal } = useBoolean();

  const { value: processDropdown, toggleBoolean: toggleProcessDropdown } =
    useBoolean();

  const filters = {
    mine: true,
    ...(modal.filters || {
      active: "all"
    })
  };

  const sortActive =
    filters.sortBy !== "updatedAt" && filters.sortBy !== undefined;

  const customStatuses = filters.customStatuses
    ? ImmutableSet([...(filters.customStatuses || [])])
    : ImmutableSet([]);

  const handleTitle = useCallback(
    (event: any) => {
      _setHomeScreenBuilderAttributes({ title: event.target.value });
    },
    [_setHomeScreenBuilderAttributes]
  );

  const handleSubTitle = useCallback(
    (event: any) => {
      _setHomeScreenBuilderAttributes({ subTitle: event.target.value });
    },
    [_setHomeScreenBuilderAttributes]
  );

  const handleButtonText = useCallback(
    (event: any) => {
      _setHomeScreenBuilderAttributes({ buttonText: event.target.value });
    },
    [_setHomeScreenBuilderAttributes]
  );

  const toggleUnread = useCallback(() => {
    if (filters.unread) {
      _setHomeScreenBuilderAttributes({
        filters: {
          ...filters,
          unread: false
        }
      });
    } else {
      _setHomeScreenBuilderAttributes({
        filters: { ...filters, unread: true }
      });
    }
  }, [filters, _setHomeScreenBuilderAttributes]);

  const toggleArchived = useCallback(() => {
    if (filters.archived) {
      _setHomeScreenBuilderAttributes({
        filters: {
          ...filters,
          archived: false
        }
      });
    } else {
      _setHomeScreenBuilderAttributes({
        filters: { ...filters, archived: true }
      });
    }
  }, [filters, _setHomeScreenBuilderAttributes]);

  const toggleOwner = useCallback(() => {
    if (filters.owner) {
      _setHomeScreenBuilderAttributes({
        filters: {
          ...filters,
          owner: false
        }
      });
    } else {
      _setHomeScreenBuilderAttributes({
        filters: {
          ...filters,
          owner: true
        }
      });
    }
  }, [filters, _setHomeScreenBuilderAttributes]);

  const updateSortFilter = useCallback(
    (newFiters: Object) => {
      _setHomeScreenBuilderAttributes({
        filters: {
          ...newFiters
        }
      });
    },
    [_setHomeScreenBuilderAttributes]
  );

  const toggleEveryone = useCallback(() => {
    if (filters.mine) {
      _setHomeScreenBuilderAttributes({
        filters: {
          ...filters,
          mine: false,
          unread: false
        }
      });
    } else {
      _setHomeScreenBuilderAttributes({
        filters: {
          ...filters,
          mine: true
        }
      });
    }
  }, [filters, _setHomeScreenBuilderAttributes]);

  const toggleCancelled = useCallback(() => {
    _setHomeScreenBuilderAttributes({
      filters: {
        ...filters,
        cancelled: !filters.cancelled
      }
    });
  }, [filters, _setHomeScreenBuilderAttributes]);

  const handleProcess = useCallback(
    (workflow: number) => {
      _setHomeScreenBuilderAttributes({
        filters: {
          ...filters,
          workflow
        }
      });
    },
    [_setHomeScreenBuilderAttributes, filters]
  );

  const removeProcess = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();

      _setHomeScreenBuilderAttributes({
        filters: {
          ...filters,
          workflow: null
        }
      });
    },
    [_setHomeScreenBuilderAttributes, filters]
  );

  const removeStatus = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      _setHomeScreenBuilderAttributes({
        filters: {
          ...filters,
          active: "all",
          customStatuses: []
        }
      });
    },
    [_setHomeScreenBuilderAttributes, filters]
  );

  const handleStandardStatus = useCallback(
    (active: string) => {
      _setHomeScreenBuilderAttributes({
        filters: {
          ...filters,
          active
        }
      });
    },
    [filters, _setHomeScreenBuilderAttributes]
  );

  const handleCustomStatus = useCallback(
    (customStatuses: ImmutableSet<number>, active: string) => {
      _setHomeScreenBuilderAttributes({
        filters: {
          ...filters,
          active,
          customStatuses: customStatuses ? customStatuses.toJS() : []
        }
      });
    },
    [filters, _setHomeScreenBuilderAttributes]
  );

  useEffect(() => {
    if (filters.unread && filters.cancelled) {
      _setHomeScreenBuilderAttributes({
        filters: {
          ...filters,
          cancelled: false
        }
      });
    }
  }, [filters.unread, filters.cancelled]);

  return (
    <Content>
      {statusModal && (
        <StatusModal
          active={filters.active}
          statusModal={statusModal}
          isWorkflow={filters.workflow}
          currentSelectedStatuses={customStatuses}
          _setStandardStatusFilter={handleStandardStatus}
          _setCheckedStatus={handleCustomStatus}
          _hideStatusModal={toggleStatusModal}
        />
      )}
      {sortbyModal && (
        <SortModal
          currentFilter={filters}
          sortbyModal={sortbyModal}
          _hideSortByModal={hideSortByModal}
          _updateFilter={updateSortFilter}
          sort={filters.sortBy}
        />
      )}
      <FormGroup>
        <label>{i18n.t(k.TITLE)}</label>
        <Input
          type="text"
          placeholder={i18n.t(k.PENDING_CONVERSATIONS)}
          value={modal.title}
          onChange={handleTitle}
          autoFocus
          required
        />
      </FormGroup>
      <FormGroup>
        <label>
          {i18n.t(k.SUB_TITLE)}
          <span>{i18n.t(k.OPTIONAL)}</span>
        </label>
        <Input
          type="text"
          placeholder={i18n.t(k.NUM_PENDING_TASKS)}
          value={modal.subTitle}
          onChange={handleSubTitle}
        />
      </FormGroup>
      <FormGroup>
        <label>
          {i18n.t(k.FILTERS)}
          <span>{i18n.t(k.SELECT_AT_LEAST_ONE)}</span>
        </label>
        <FilterGroup>
          <RoundButton
            type="button"
            active={filters.unread}
            onClick={toggleUnread}
            disabled={!filters.mine}
          >
            <Icons type="unreads" />
            {i18n.t(k.UNREAD)}
          </RoundButton>
          <RoundButton
            type="button"
            active={filters.owner}
            onClick={toggleOwner}
          >
            <Icons type="iown" />
            {i18n.t(k.I_OWN)}
          </RoundButton>
          <RoundButton
            type="button"
            active={sortActive}
            onClick={toogleSortModal}
          >
            <Icons type="sortBy" />
            {sortActive ? sorByItemNames[filters.sortBy] : i18n.t(k.SORT_BY2)}
            {sortActive ? (
              <ClearSort>
                <Icons type="blueCross" />
              </ClearSort>
            ) : (
              <Arrow>
                <Icons type="triangleDown" />
              </Arrow>
            )}
          </RoundButton>
          <RoundButton
            type="button"
            active={
              customStatuses.size > 0 ||
              filters.active !== "all" ||
              !filters.active
            }
            onClick={toggleStatusModal}
          >
            <Icons type="status" /> {i18n.t(k.STATUS)}
            {(customStatuses.size > 0 ||
              filters.active !== "all" ||
              !filters.active) && (
              <RemoveStatusButton
                active={customStatuses.size > 0 || filters.active}
                type="button"
                onClick={removeStatus}
              >
                <Icons type="close" />
              </RemoveStatusButton>
            )}
          </RoundButton>
          <RoundButton
            type="button"
            active={!filters.mine}
            onClick={toggleEveryone}
          >
            <Icons type="everyOne" />
            {i18n.t(k.EVERYONE)}
          </RoundButton>
          <RoundButton
            type="button"
            active={filters.cancelled}
            onClick={toggleCancelled}
            disabled={filters.unread}
          >
            <Icons type="status" color={blue} />
            {i18n.t(k.CANCELLED)}
          </RoundButton>
          <ProcessButton
            type="button"
            ref={processRef}
            active={!!filters?.workflow}
            onClick={toggleProcessDropdown}
          >
            {/* eslint-disable-next-line no-extra-boolean-cast */}
            {!!filters?.workflow ? (
              <>
                <span>
                  <WorkflowName id={filters.workflow} />
                </span>
                <RemoveButton
                  active={!!filters?.workflow}
                  type="button"
                  onClick={removeProcess}
                >
                  <Icons type="close" />
                </RemoveButton>
              </>
            ) : (
              <>
                <Icons type="everyOne" />
                {i18n.t(k.PROCESS)}
              </>
            )}
            {processDropdown && (
              <ProcessDropdown
                handleClose={toggleProcessDropdown}
                outerRef={processRef}
                handleSelect={handleProcess}
                value={filters.workflow}
              />
            )}
          </ProcessButton>
          <RoundButton
            type="button"
            active={filters.archived}
            onClick={toggleArchived}
          >
            <Icons type="archive" fill={blue} />
            {i18n.t(k.ARCHIVED)}
          </RoundButton>
        </FilterGroup>
      </FormGroup>
      <FormGroup>
        <label>{i18n.t(k.BUTTON_TEXT)}</label>
        <Input
          type="text"
          placeholder={`"${i18n.t(k.TAKE_ME_THERE)}"`}
          value={modal.buttonText}
          onChange={handleButtonText}
        />
      </FormGroup>
      <FormGroup>
        <label>{i18n.t(k.WHEN_EMPTY)}</label>
        <EmptyDropdown
          emptyMessage={modal.emptyMessage}
          value={modal.displayEmpty}
          handleSelect={_setHomeScreenBuilderAttributes}
        />
      </FormGroup>
      <FormGroup>
        <label>{i18n.t(k.TILE_COLOR)}</label>
        <FilterGroup>
          {tileColors.map(color => (
            <TileColor
              onClick={() =>
                _setHomeScreenBuilderAttributes({ tileColor: color })
              }
              key={color}
              color={color}
              active={modal.tileColor === color}
            />
          ))}
        </FilterGroup>
      </FormGroup>
    </Content>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  modal: app.orgSettings.homeScreenBuilderDialog
});

export default connect(mapStateToProps, {
  _setHomeScreenBuilderAttributes: setHomeScreenBuilderAttributes
})(Conversations);
