// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";

import { Navigation as StyledNavigation, Item } from "./styles";
import Icon from "src/icons";

type Props = {
  current: string
};

const Navigation = ({ current }: Props) => {
  return (
    <StyledNavigation>
      <Item active={current}>
        <Icon type="bell" />
        <strong>{i18n.t(k.NOTIFICATIONS)}</strong>
      </Item>
    </StyledNavigation>
  );
};

export default Navigation;
